@import "~@/styles/variables.scss";
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow-y: auto;
}

.el-select-dropdown__item.selected {
  font-weight: normal;
}

ul li > .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}

.el-tree-node__label {
  font-weight: normal;
}

.el-tree > .is-current .el-tree-node__label {
  font-weight: 700;
  color: #409eff;
}

.el-tree > .is-current .el-tree-node__children .el-tree-node__label {
  font-weight: normal;
  color: #606266;
}
